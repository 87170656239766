// 引入工具函数
import { http, httpimg } from '../index'



// 时间场所-列表
export const getEventPlaceList = params => http.get('/ohealth/api/v1/epidemicControl/event/getEventPlaceList', params)

// 查询事件场所报表信息-列表
export const getEventPlaceReport = params => http.post('/ohealth/api/v1/epidemicControl/report/listQuery', params)

// 导出事件场所报表信息-列表
export const exportEventPlaceReport = params => httpimg.post('/ohealth/api/v1/epidemicControl/report/exportEventPlaceReport', params)

// 同步检测
export const pullCovidResult = params => http.get('/ ohealth / api / v1 / isolation / resident / pullCovidResult', params)

// 人员详情列表
export const userListQuery = params => http.get('/ohealth/api/v1/epidemicControl/report/userListQuery', params)



// 任务分配
export const countApi = params => http.post('/ohealth/api/v1/epidemicControl/analysis/count', params)

// 流调结果分布情况
export const count2Api = params => http.post('/ohealth/api/v1/epidemicControl/analysis/resultCount', params)

// 发送协查函情况
export const count3Api = params => http.post('/ohealth/api/v1/epidemicControl/analysis/surveyLetterCount', params)

// 人群判定分布
export const count5Api = params => http.post('/ohealth/api/v1/epidemicControl/analysis/surveyTypeCount', params)

// 人员流调处理任务数
export const count4Api = params => http.post('/ohealth/api/v1/epidemicControl/analysis/surveyUserCount', params)

// 协查信息来源
export const count1Api = params => http.post('/ohealth/api/v1/epidemicControl/analysis/assistCount', params)